









































































































































































































































































































































import { Mixin_list } from "@/mixin";
// import { api_city, city } from "@/http/city";
import { api_order, orderDetail } from "@/http/order";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";
import DatePicker from "@/components/datePicker/index.vue";
import { api_store, store } from "@/http/store";

@Component({
  components: {
    Page,
    Table,
    DatePicker,
  },
})
export default class extends Mixin_list<orderDetail>(api_order.get_list) {
  activeIndex = 1;
  tabList = [
    {
      name: "全部订单",
      status: "",
      index: 1,
    },
    {
      name: "待付款",
      status: 0,
      index: 2,
    },
    {
      name: "待存入",
      status: 1,
      index: 3,
    },
    {
      name: "待取走",
      status: 2,
      index: 4,
    },
    {
      name: "待清洗",
      status: 3,
      index: 5,
    },
    {
      name: "清洗中",
      status: 4,
      index: 6,
    },
    {
      name: "待送回",
      status: 5,
      index: 7,
    },
    {
      name: "已完成",
      status: 6,
      index: 8,
    },
    {
      name: "售后中",
      status: 7,
      index: 9,
    },
  ];
  columns = [
    {
      label: "商品信息",
      slot: "info",
      prop: "info",
      width: 300,
    },
    {
      label: "用户信息",
      prop: "",
      slot: "userInfo",
      width: 300,
    },
    {
      label: "会员卡",
      prop: "",
      slot: "level",
      // width: 300,
    },
    {
      label: "订单来源",
      prop: "",
      slot: "orderSource",
      width: 200,
    },
    {
      label: "入库人",
      prop: "",
      slot: "depositor",
      width: 140,
    },
    {
      label: "支付方式",
      prop: "",
      slot: "payType",
      width: 120,
    },
    {
      label: "订单状态",
      prop: "",
      slot: "status",
      width: 120,
    },
    {
      label: "操作",
      prop: "",
      slot: "operation",
    },
  ];
  getImageUrl(cover: string) {
    if (cover && typeof cover === "string") {
      // 将字符串按逗号分割成数组
      const coverIds = cover.split(",");
      // 取数组的第一个元素作为图片ID
      const coverId = coverIds[0].trim();
      // 拼接图片URL
      return `https://images.xiyuebiaoju.com/${coverId}`;
    }
  }
  getMiddleIndex(str: String) {
    return Math.floor(str.length / 2);
  }
  getFirstHalf(str: String) {
    const middle = this.getMiddleIndex(str);
    return str.slice(0, middle);
  }
  getSecondHalf(str: String) {
    const middle = this.getMiddleIndex(str);
    return str.slice(middle);
  }
  handleSelect(key: any, keyPath: any) {
    this.tabList.forEach((v) => {
      if (v.index == key) {
        this.filter.status = v.status;
      }
    });
    this.get_list();
  }
  status(status: number) {
    switch (status) {
      case 0:
        return "待支付";//#在柜子上(1，待取走)完成下单，还为取走的订单。
      case 1:
        return "待取走";//# 已经支付完成的，还未入库的订单
      case 2:
        return "待入库";//#已经入库，等待配送，送到清洗工厂的订单
      case 3:
        return "待进场";//# 已经进入工正在清洗，等待质检的订单
      case 4:
        return "清洗中";
      case 5:
        return "已质检";
      case 6:
        return "待出库";//# 已经质检等待配送，送回到门店的订单
      case 7:
        return "待到店";
        case 8:
        return "待派送";
      case 9:
        return "完成";
    }
  }
  address(data: any) {
    return `${data.province}${data.city}${data.region}${data.address}`;
  }
  cityList: any[] = [];



  click_cell(row: orderDetail) {
    return this.$router.push(`/order/edit/?id=${row.id}`);
  }
  filter = {
    create_time_after: "",
    create_time_before: "",
    order_type: "",
    status: "" as string | number,
    phone: "",
    order_no: "",
    store: "",
  };
  resetFilter = {
    ...this.filter,
  };
  // 订单类型
  orderType(orderType: any) {
    switch (orderType) {
      case 1:
        return "微信";
      case 2:
        return "抖音";
      case 3:
        return "柜子";
      case 4:
        return "平台";
      default:
        return "未知";
    }
  }
  onUpdateRange([startStr, endStr]: [string, string]) {
    this.filter.create_time_after = endStr;
    this.filter.create_time_before = startStr;
  }
  reset() {
    this.filter = { ...this.filter, ...this.resetFilter };
    (this.$refs.datePicker as Vue & { clearDates: () => void }).clearDates();
    this.find();
  }
  // 获取头像
  get_avatar(value: string) {
    // 如果头像包含http，直接返回
    if (value.includes("http")) {
      return value;
    } else {
      return `https://images.xiyuebiaoju.com/${value}`;
    }
  }
  // 支付方式
  payType(type: number) {
    switch (type) {
      case 1:
        return "余额支付";
      case 2:
        return "微信支付";
      default:
        return "未知";
    }
  }
  // 会员等级
  get_level(level: number) {
    switch (level) {
      case 1:
        return "普通会员";
      case 2:
        return "黄金会员";
      case 3:
        return "钻石会员";
      default:
        return "未知";
    }
  }
  // 获取门店列表
  storeList:any[] = []
    async get_store_list(){
      const res = await api_store.get_list({page_size:10000})
      const list:any[] = [{label:'全部',value:''}]
      res.results.forEach((item:any)=>{
          item.value = item.id,
          item.label = item.name
      })
      this.storeList = list.concat(res.results)
  }
  created() {
    this.get_store_list();
  }
  // async get_city_list() {
  //   const res = await api_city.get_list({ page_size: 200 });
  //   const list: any[] = [{ city: "全部", value: "" }];
  //   res.results.forEach((item: any) => {
  //     item.value = item.city;
  //   });
  //   this.cityList = list.concat(res.results);
  // }
}
